<template>
	<div class="dealer-search-store">
		<h2>{{ name }}</h2>
		<div class="grid-columns-2">
			<div v-if="location">
				<template v-if="position.latitude && distance">
					<LinkInline :to="googleMapsLink">{{ distance }} unna</LinkInline>
					<div>{{ location }}</div>
				</template>
				<template v-else>
					<LinkInline :to="googleMapsLink">{{ location }}</LinkInline>
				</template>
			</div>
			<div>
				<div
					class="opening-hours"
					v-if="openingHoursToday"
				>
					Åpent {{ openingHoursToday }}
				</div>
				<div v-if="interactive">
					<TnButton
						size="xs"
						secondary
						class="margin-top-xs"
						v-if="isMyStore"
						disabled
						>Min Telenorbutikk</TnButton
					>
					<TnButton
						size="xs"
						secondary
						class="margin-top-xs"
						v-else
						@click="setAsStore"
						>Sett som min butikk</TnButton
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import capitalize from "~/helpers/formatting/capitalize";
import { mapGetters } from "vuex";

// convert degrees to radians
function deg2rad(deg) {
	// radians = degrees * pi/180
	return (deg * Math.PI) / 180;
}

// round to the nearest 1/1000
function round(x, roundValue) {
	return Math.round(x * roundValue) / roundValue;
}

function doMathStuffToFindDistance(dealer, location) {
	const Rk = 6373; // mean radius of the earth (km) at 39 degrees from the equator

	const t1 = dealer.lat; // Dealer lat
	const n1 = dealer.lon; // Dealer long
	const t2 = location.latitude;
	const n2 = location.longitude;

	// convert coordinates to radians
	const lat1 = deg2rad(t1);
	const lon1 = deg2rad(n1);
	const lat2 = deg2rad(t2);
	const lon2 = deg2rad(n2);

	// find the differences between the coordinates
	const dlat = lat2 - lat1;
	const dlon = lon2 - lon1;

	// here's the heavy lifting
	const a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); // great circle distance in radians

	const dk = c * Rk; // great circle distance in km
	const km = round(dk, 1000); // round the results down to the nearest 1/1000
	if (km < 1) {
		return km * 1000 + " meter";
	} else {
		return round(km, 10) + " kilometer";
	}
}

export default defineNuxtComponent({
	name: "DealerSearchStore",

	props: {
		store: {
			type: Object,
			required: true,
		},
		interactive: {
			type: Boolean,
		},
	},

	computed: {
		...mapGetters("geolocation", ["position"]),
		name() {
			return this.store.name;
		},
		location() {
			return capitalize(this.store.address.postPlace);
		},
		distance() {
			if (this.store.address.location) return doMathStuffToFindDistance(this.store.address.location, this.position);
			return `${this.store.address.streetAddress} ${this.store.address.postCode} ${
				this.store.address.postPlace || this.store.address.municipality
			}`;
		},
		openingHoursToday() {
			return this.store.openingHours;
		},
		googleMapsLink() {
			return `https://www.google.com/maps/search/?api=1&query=${this.store.name}`;
		},
		isMyStore() {
			if (!this.$store.state.telenorbutikken.closestStore) return false;
			return this.store.dealerId === this.$store.state.telenorbutikken.closestStore.dealerId;
		},
	},

	methods: {
		setAsStore() {
			this.$store.commit("telenorbutikken/setClosestStore", this.store);
			this.$store.commit("telenorbutikken/setOverride", true);
		},
	},
});
</script>

<style lang="scss" scoped>
.dealer-search-store {
	border-bottom: 1px solid $color-neutrals-100-tint;
	padding: $spacing-l 0;
}

h2 {
	font-weight: bold;
	margin-bottom: $spacing-s;
}

.details {
	color: $color-neutrals-600-shade;

	@include font-text-s;
}

.opening-hours {
	white-space: break-spaces;
}
</style>
